.side-bar {
  left: 0;
  height: 100%;
  background-color: #6d0f2e;
  color: white;
  padding: 1.2em;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  text-wrap: wrap;
  transition: transform 0.3s ease;
}

/* Hide sidebar when screen width is less than 720px */
@media (max-width: 720px) {
  .side-bar {
    transform: translateX(-100%);
  }
}

/* Show sidebar */
.side-bar.open {
  transform: translateX(0);
}

.sidebar-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #6d0f2e;
  color: white;
  /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
  opacity: 0;
  visibility: hidden;
  /* transition: visibility 0s linear 0.5s, opacity 0.5s linear; */
}

.sidebar-tab.visible {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}


.left-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.left-arrow-container {
  background-color: #861F41;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .2rem;
}