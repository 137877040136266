/* .message-box {
  display: grid;
  grid-template-rows: auto auto;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px 0;
  word-wrap: break-word;
  font-family: Arial, sans-serif;
  justify-items: center;
  width: 100%;
} */

.message-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 10px 0;
  word-wrap: break-word;
  font-family: Arial, sans-serif;
  justify-items: center;
  width: 100%;
}

.message-box:hover {
  background-color: #D3D3D3;
}

.icon {
  justify-self: start;
}

.message {
  white-space: pre-wrap;
}

.send-error {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: larger;
}

.try-again {
  text-decoration: underline;
  cursor: pointer;
}
