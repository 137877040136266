/* .questionContainer {
  border: 2px solid #000;
  padding: 20px;
  margin: 20px auto;
  text-align: center;
  border-radius: 10px;
  max-width: 50%;
}

.labelSpacing {
  margin-left: 10px;
}

.button {
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
} */

.flex-grid {
  display: flex;
}

.col {
  flex: 1;
}

@media (max-width: 1100px) {

  .flex-grid {
    display: block;

    .col {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}

button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
  cursor: default;;
}