.surveyContainer {
    border: 2px solid #000; 
    padding: 20px;
    margin: 20px auto;
    text-align: center; 
    border-radius: 10px;
    max-width: 50%; 
  }
  
  .labelSpacing {
    margin-left: 10px; 
  }