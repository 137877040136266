.container {
    display: flex;
    height: calc(100% - 64px);
}

.sidebar {
    flex: 1;
}

.main-page {
    width: 100%;
    display: flex;
    flex: 4;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

}

.text-area {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    min-height: 5rem;

}

.input-box {
    /* width: min(63%, 60em); */
    width: min(calc(100% - 1.2rem), 60em);
    background-color: none;
    padding-bottom: 1.2em;
    display: flex;
    justify-content: center;
    align-self: center;
    flex-direction: column;

}

.response-area {
    /* width: min(58%, 58em); */
    width: min(calc(100% - 2.2rem), 56em);
}


button {
    border: none;
    border-radius: 4px;
    background-color: #6d0f2e;
    cursor: pointer;
    color: white;
    padding: 1.2em;
}

button:hover {
    background-color: #7d1739;
    color: white;
}

.eft-caption {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 4px;
    margin-bottom: .2em;
    background-color: white;
    margin-bottom: .5em;
}

.start-message {
    font-size: max(3vw, 1.2rem);
    color: #D3D3D3;
    cursor: default;
    padding-bottom: 1.2rem;
}

.start-about {
    font-size: max(1vw, .8rem);
    color: #D3D3D3;
}

.center-start-message {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.loading-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 10%;


}

.loading {
    font-weight: bolder;
    display: inline-block;
    font-family: monospace;
    clip-path: inset(0 3ch 0 0);
    animation: l 1s steps(4) infinite;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}

.cue-index {
    background-color: #F0F0F0;
    padding: .1rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px;
    border: none;
}

.cue-index:hover {
    background-color: #D3D3D3;
}

.next-arrow:hover {
    background-color: #F0F0F0;
    border-radius: 4px;
    color: var(--primary);

}

.next-arrow {
    background: none;
    color: var(--primary);
}

.fetching-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


/* custom scrollbar */
::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}